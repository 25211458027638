import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import SongsList from "../../../components/song/SongsList";
import { searchIn, searchNumberIn } from "../../../service/functions";
import i18n from "../../../i18n";
import NavBar from "../../../core/NavBar";
import SearchBox, {
  defaultSearch,
} from "../../../components/unclassified/SearchBox";
import { connect } from "react-redux";
import {
  setSettingStoreValueAction,
} from "../../../store/setting/settingStoreAction";
import { MusicNote } from "@mui/icons-material";
import FloationActionButton from "../../../components/unclassified/FloationActionButton";
import { useHistory } from "react-router-dom";

const filterSongs = (list, category, search, primaryCategoriesList) => {
  if (category === -1 && search === "") {
    // display all inPrimaryList
    return list.filter(
      (song) =>
        !song.category || primaryCategoriesList.includes(song.category?.id)
    );
  } else if (category === -1 && search !== "") {
    // display search
    return list.filter(
      (song) =>
        searchIn(song.name, search) || searchNumberIn(song.number, search)
    );
  } else if (search === "") {
    // display category
    return list.filter(
      (song) => song.category && category === song.category.id
    );
  } else {
    // return category search
    return list.filter(
      (song) =>
        (song.category &&
          category === song.category.id &&
          searchIn(song.name, search)) ||
        searchNumberIn(song.number, search)
    );
  }
};

const getNavTitle = (menu) => {
  const title = menu.filter(
    (m) => m.link === decodeURI(window.location.pathname)
  );
  return title.length && "name" in title[0] ? title[0].name : "";
};

const getCategoriesPrimaryList = (arr) =>
  (arr || []).map((e) => e.inPrimaryList && e.id).filter(Boolean);

const Category = ({
  menu,
  songList = [],
  currentSong,
  setSearch,
  search,
  primaryCategoriesList,
  songListScroll,
  setSongListScroll,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [initScroll, setInitScroll] = useState(0);

  // auto reset search value
  useEffect(() => {
    const newTitle = getNavTitle(menu);
    if (search.title !== newTitle) {
      setSearch({ ...defaultSearch, title: newTitle });
    }
  }, [search, setSearch, menu, id, initScroll, setInitScroll]);

  // auto set scroll position when return to the page
  useEffect(() => {
    if(songListScroll?.categoryId === (id??0)){
      setInitScroll(songListScroll?.position ?? 0);
    }else{
      setSongListScroll({ categoryId: (id??0), position: 0 })
      setInitScroll(initScroll===0?1:0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, songListScroll?.categoryId, initScroll, setSongListScroll]);

  const scrollHandler = useCallback(
    (event) =>
      setSongListScroll({ categoryId: (id??0), position: event.scrollOffset }),
    [id, setSongListScroll]
  );

  return (
    <>
      <NavBar
        title={search.title}
        children={<SearchBox search={search} setSearch={setSearch} />}
      />
      <SongsList
        onScroll={scrollHandler}
        scrollOffset={initScroll}
        list={
          songList
            ? filterSongs(
                songList,
                id ? parseInt(id) : -1,
                search.value,
                primaryCategoriesList
              )
            : null
        }
        emptyMessage={
          search.value === ""
            ? i18n.t("app:song:no songs")
            : i18n.t("main:emptySearch")
        }
      />
      {currentSong ? (
        <FloationActionButton
          onClick={() => history.push(`/app/song-${currentSong}`)}
          icon={<MusicNote sx={{ color: "white" }} />}
        />
      ) : null}
    </>
  );
};

export default connect(
  (state) => ({
    menu: state.setting.appMenu,
    currentSong: state.setting.currentSong,
    search: state.setting?.search || { ...defaultSearch, title: "" },
    songList: state.setting.appDatas?.song,
    primaryCategoriesList: getCategoriesPrimaryList(
      state.setting.appDatas?.category
    ),
    songListScroll: state.setting.songListScroll,
  }),
  (dispach) => ({
    setSearch: (value) => dispach(setSettingStoreValueAction("search", value)),
    setSongListScroll: (value) =>
      dispach(setSettingStoreValueAction("songListScroll", value)),
  })
)(Category);
