import chordsKeys from "./chords";

const ChordParser = class {
  constructor() {
    this.chordRegex = new RegExp(/\[\(?[a-zÀ-ú#0-9-\s/]+\)?\]/gi);
  }

  removeAllChords = (text) => text.replace(this.chordRegex, "");

  changeChordInternational = (chord, currentFormat, targetFormat) =>
    !chordsKeys[currentFormat].includes(chord)
      ? chord
      : chordsKeys[targetFormat][chordsKeys[currentFormat].indexOf(chord)];

  transpose = (chord, format, tone = 0) => {
    const size = chordsKeys.transpose[format].length;
    const val = chordsKeys.level[format][chord] + tone;
    const id = val < 0 ? val + size : val >= size ? val - size : val;
    return chordsKeys.transpose[format][id];
  };

  transposeChord = (chord, tone = 0) => {
    return this.transpose(
      chord,
      chordsKeys.en.includes(chord) ? "en" : "fr",
      tone
    );
  };

  getNote = (tone) => {
    if (chordsKeys.level.fr[tone] !== undefined) {
      return chordsKeys.transpose.en[chordsKeys.level.fr[tone]];
    } else if (chordsKeys.level.en[tone] !== undefined) {
      return chordsKeys.transpose.en[chordsKeys.level.en[tone]];
    }
  };

  isChord = (text) => {
    text = this.capitalize(text.replace(/dim|sus/i, "").replace(/[0-9-m#()]/g, "").replace(/M$/g, ""));
    let chords = [];
    if (text.includes("/") || text.includes(" ")) {
      chords = text.split(/\/|\s/);
      chords[0] = this.capitalize(chords[0].replace(/dim|sus/i, "").replace(/[0-9-m#()]/g, "").replace(/M$/g, ""));
    }

    return chords.length === 0
      ? chordsKeys.fr.includes(text) || chordsKeys.en.includes(text)
      : chordsKeys.fr.includes(chords[0]) || chordsKeys.en.includes(chords[0]);
  };

  capitalize = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  };

  detectChords = (text = "") =>
    text.match(this.chordRegex) === null ? false : true;

  detectEnFormat = (arr) =>
    arr.reduce((a, v) => (chordsKeys.en.includes(v) ? a + 1 : a), 0) >
    (arr.length * 7) / 10;

  detectSongChordFormat = (text) => {
    const chords = text.match(this.chordRegex);
    return this.detectEnFormat(chords || []) ? "en" : "fr";
  };

  parseSong = (text, format = "fr", tone = 0) => {
    const currentFormat = this.detectSongChordFormat(text);
    const self = this;
    let newSong = "",
      isChord = false,
      currentChord = "";

    text.split("").forEach((c) => {
      if (c === "[") {
        isChord = true;
        currentChord = "";
      } else if (c === "]") {
        const hasParenthesis = currentChord.match(/[()]/g) !== null;
        if (hasParenthesis) {
          currentChord = currentChord.replace(/[()]/g, "");
        }

        let chordsText = "";
        currentChord.split(/\/|\s/).forEach((c, idx) => {
          const chord = c.replace(/dim|sus/i, "").replace(/[0-9-m]/g, "").replace(/M$/g, "");
          const rest = c.replace(chord, "");
          let newChord = chord;

          if (currentFormat !== format) {
            newChord = self.changeChordInternational(
              newChord,
              currentFormat,
              format
            );
          }
          if (tone !== 0) {
            newChord = self.transpose(newChord, format, tone);
          }

          chordsText +=
            newChord !== ""
              ? `${
                  idx > 0 ? (text.includes("/") ? "/" : " ") : ""
                }${this.capitalize(newChord)}${rest}`
              : "";

          isChord = false;
        });

        if (chordsText.length) {
          newSong += hasParenthesis ? `[(${chordsText})]` : `[${chordsText}]`;
        }
      } else if (!isChord) {
        newSong += c;
      } else {
        currentChord += c;
      }
    });
    return newSong;
  };

  splitChordsByLines = (text) => {
    let song = "",
      chords = "";
    const arr = text.split("\n");
    arr.forEach((e, idx) => {
      const chordsLine = e.match(this.chordRegex);
      const n = idx !== arr.length - 1 ? "\n" : "";
      song += e.replace(this.chordRegex, "") + n;
      chords +=
        (chordsLine ? chordsLine.join(" ").replace(/[[\]]/g, "") : "") + n;
    });
    return [song, chords];
  };
};

export { ChordParser, chordsKeys };
