import React from 'react'
import { Grid, Typography } from '@mui/material'
import { ChordParser } from '../../assets/AppChordPro'
import { isPossibleTwoColums } from './../../service/functions'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0!important',
      paddingBottom: '0!important'
    }
  },
  chorus: {
    fontWeight: 'bold!important',
    '& span': {
      // textTransform: 'capitalize!important',
      fontWeight: 'normal!important'
    }
  },
  verse: {
    fontSize: '16px',
    '& span': {
      // textTransform: 'capitalize'
    }
  },
  header: {
    fontWeight: '100',
    fontSize: '0.8em'
  },
  chord: {
    display: 'inline-block',
    position: 'relative',
    top: '-1.4em',
    width: 0,
    right: '10px',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '0.8em',
    textTransform: 'none!important',
    color: theme.palette.chords.main
  }
}))

const betterDisplay = text =>
  text.replace(/\s*([!?:;]\n)|\s*([!?:;])$/g, '\u00A0$1$2')

const isChorus = name => typeof name === 'string' && ['refrain', 'r'].includes(name.toLowerCase())

const isSpecificVerseBefore = (name = '') =>
typeof name === 'string' && ['pont', 'coda'].includes(name.toLowerCase())

const isSpecificVerseAfter = (name = '') =>
typeof name === 'string' && ['coda', 'refrain final', 'fin', 'doxologie', 'contre-chant'].includes(
    name.toLowerCase()
  )

const detectChorusPosition = content => {
  for (let k in content) {
    if (isChorus(content[k].name)) return k
  }
  return -1
}

const addChorusEach = content => {
  const pos = detectChorusPosition(content)
  if (pos !== -1) {
    let newContent = []
    content.forEach((e, idx) => {
      if (
        idx > 0 &&
        Math.abs(idx - pos) > 1 &&
        !isSpecificVerseBefore(content[idx - 1].name) &&
        !isSpecificVerseAfter(content[idx].name)
      ) {
        newContent.push(content[pos])
        newContent.push(content[idx])
      } else {
        newContent.push(content[idx])
      }
    })
    return newContent
  }
  return content
}

const SongContent = ({ content, settings, fullSize = false }) => {
  const classes = useStyles()
  const chordParser = new ChordParser()
  let chorusName

  if (settings.chorusEach && content) content = addChorusEach(content)

  const allowTwoColumns =
    !fullSize &&
    content.length > 1 &&
    settings.allowTwoColumns &&
    isPossibleTwoColums(settings.fontSize)

  return (
    <>
      {content.map((element, idx) => {
        if (isNaN(parseInt(element.name))) {
          chorusName = isChorus(element.name) ? (
            'R. '
          ) : element.name !== '' ? (
            <span className={classes.header}>{`${element.name} :\n`}</span>
          ) : null
        } else if(element.name) {
          chorusName = `${element.name}. `
        } else {
          chorusName = ''
        }

        return (
          <Grid key={idx} item xs={12} sm={allowTwoColumns ? 6 : 12} sx={{paddingLeft:'0px!important', paddingRight: '16px'}} >
            {!settings.displayChords ||
            !chordParser.detectChords(betterDisplay(element.text)) ? (
              <ContentOnly
                key={idx}
                classes={classes}
                settings={settings}
                element={element}
                chorusName={chorusName}
                chordParser={chordParser}
              />
            ) : settings.chordsPosition === 0 ? (
              <ContentIntegratedChords
                key={idx}
                classes={classes}
                settings={settings}
                element={element}
                chorusName={chorusName}
                chordParser={chordParser}
              />
            ) : (
              <ContentSeparetedChords
                key={idx}
                positionLeft={settings.chordsPosition === -1}
                classes={classes}
                settings={settings}
                element={element}
                chorusName={chorusName}
                chordParser={chordParser}
              />
            )}
          </Grid>
        )
      })}
    </>
  )
}

SongContent.propTypes = {
  /** song content [{name: "", text: ""}] */
  content: PropTypes.array.isRequired,
  /** song settings */
  settings: PropTypes.object.isRequired,
  /** display in only one column */
  fullSize: PropTypes.bool
}

export default SongContent

const ContentOnly = ({
  classes,
  settings,
  element,
  chorusName,
  chordParser
}) => (
  <SongTypo
    classes={classes}
    bold={element.bold}
    fontSize={settings.fontSize}
    chorusName={chorusName}
    children={chordParser.removeAllChords(betterDisplay(element.text))}
  />
)

const ContentIntegratedChords = ({
  classes,
  settings,
  element,
  chorusName,
  chordParser
}) => (
  <SongTypo
    classes={classes}
    bold={element.bold}
    fontSize={settings.fontSize}
    integratedChords={true}
    chorusName={chorusName}
    children={
      <DisplaySongChords
        chordParser={chordParser}
        text={chordParser.parseSong(
          betterDisplay(element.text),
          settings.chordFormat,
          settings.tone
        )}
      />
    }
  />
)

const ContentSeparetedChords = ({
  classes,
  settings,
  element,
  chorusName,
  chordParser,
  positionLeft
}) => {
  const content = chordParser.splitChordsByLines(
    chordParser.parseSong(
      betterDisplay(element.text || ''),
      settings.chordFormat,
      settings.tone
    )
  )

  return (
    <Grid
      container
      direction={positionLeft ? 'row-reverse' : 'row'}
      spacing={1}
    >
      <Grid item xs={9}>
        <SongTypo
          classes={classes}
          bold={element.bold}
          fontSize={settings.fontSize}
          chorusName={chorusName}
          children={content[0]}
        />
      </Grid>
      <Grid item xs={3}>
        <SongTypo
          classes={classes}
          bold={element.bold}
          fontSize={settings.fontSize}
          children={content[1]}
        />
      </Grid>
    </Grid>
  )
}

const SongTypo = ({
  children,
  chorusName = '',
  classes,
  bold,
  fontSize,
  integratedChords
}) => (
  <Typography
    classes={{
      body1: classes.chorus || {},
      body2: classes.verse || {}
    }}
    paragraph={true}
    style={{
      whiteSpace: 'pre-wrap',
      fontSize: bold ? fontSize + 1 : fontSize,
      wordSpacing: '0.16em',
      lineHeight: integratedChords ? fontSize * 2 + 6 + 'px' : 'auto'
    }}
    variant={bold ? 'body1' : 'body2'}
  >
    {chorusName}
    {children}
  </Typography>
)

const DisplaySongChords = ({ chordParser, text }) => {
  const classes = useStyles()
  // add | to detect true chords and escape little word "la ..."
  return text.replace(/\[/g, '[|').split(/[[\]]/).map((e, idx) =>
  e[0]==='|' && chordParser.isChord(e.replace('|','')) ? (
      <span key={idx} className={classes.chord}>
        {e.replace('|','')}
      </span>
    ) : (
      e
    )
  )
}
