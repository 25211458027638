import React, { useMemo, useRef } from 'react'
import { IconButton, TextField } from '@mui/material'
import { Search, Close, ArrowForward } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  openedSearch: {
    position: 'relative',
    right: -40,
    marginLeft: -35,
    maxWidth: 250,
    width: 250,
    opacity: 1,
    transition: 'max-width 0.5s, opacity 0.2s',
    '& input': {
      paddingRight: 32
    }
  },
  closedSearch: {
    position: 'relative',
    right: -40,
    maxWidth: 0,
    opacity: 0,
    transition: 'max-width 0.5s, opacity 0.2s 0.1s'
  },
  buttonLabelOffset: {
    position: 'relative',
    top: 6
  }
}))

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }
  return [htmlElRef, setFocus]
}

const defaultSearch = { open: false, value: '' }

let timeOut

const SearchBox = ({
  label = '',
  search = defaultSearch,
  setSearch,
  onSearch,
  searchButton
}) => {
  const classes = useStyles()
  const [inputRef, setInputFocus] = useFocus()

  useMemo(() => {
    if (!inputRef.current && search?.open && !search.value)
      setSearch({ ...search, open: !search.open })
  }, [inputRef, search, setSearch])

  return (
    <>
      <TextField
        variant='standard'
 inputMode="numeric"
        label={search.open ? label : ''}
        className={search.open ? classes.openedSearch : classes.closedSearch}
        inputRef={inputRef}
        value={search.value}
        onChange={e => {
          setSearch({ ...search, value: e.target.value })
          if (onSearch && e.target.value === '') onSearch()
        }}
        onKeyDown={e => {
          if (onSearch && e.key === 'Enter') onSearch(search)
        }}
        onBlur={() => {
          if (search.value === '') {
            timeOut = setTimeout(() => {
              if (search.value === '') {
                setSearch({ ...search, value: '', open: false })
              }
            }, 5000)
          }
        }}
        onFocus={() => {
          if (timeOut) clearTimeout(timeOut)
        }}
      />
      {!search.open || !searchButton ? (
        <IconButton
          aria-label='search'
          color='inherit'
          className={label ? classes.buttonLabelOffset : null}
          onClick={() => {
            if (!search.open || search.value!=='') {
              setInputFocus()
            } else if (onSearch) {
              onSearch({ ...search, value: '' })
            }
            setSearch({ ...search, value: '', open: search.value===''? !search.open:true })
          }}
        >
          {search.open ? <Close /> : <Search />}
        </IconButton>
      ) : (
        <IconButton
          aria-label='load search'
          color='inherit'
          className={label ? classes.buttonLabelOffset : null}
          onClick={() => {
            if (onSearch) onSearch(search)
          }}
        >
          <ArrowForward />
        </IconButton>
      )}
    </>
  )
}

SearchBox.propTypes = {
  /** { value: '', open: false } */
  search: PropTypes.object,
  setSearch: PropTypes.func,
  onSearch: PropTypes.func,
  searchButton: PropTypes.bool
}

export default SearchBox

export { defaultSearch }
