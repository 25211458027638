import {
  defaultPrintMassSheetSettings,
  defaulOsmdSettings,
  defaultMassSheetSettings
} from '../../config/constants'

// settings store reducer for app setings (theme, songList, drawerReduced, ...)

const initialState = {
  darkTheme: false,
  drawer: false,
  drawerReduced: false,
  snackbar: { message: '', type: '', time: null },
  appNotifications: { appNotifications: [], userMessages: 0, adminMessages: 0 },
  appDatas: {},
  appMenu: [],
  songList: null, // empty list
  celebration: null,
  celebrationList: null,
  search: null,
  currentSong: null,
  osmdSettings: defaulOsmdSettings,
  printMassSheetSettings: defaultPrintMassSheetSettings,
  massSheetSettings: defaultMassSheetSettings,
  pdfScale: 1,
  guidedTour: {},
  songListScroll: {}
}

export default function FormReducer (state = initialState, action) {
  switch (action.type) {
    case 'INIT_SETTING_STORE':
      return { ...action.payload }
    case 'SET_SETTING_STORE_VALUE':
      if (action.payload.subfield) {
        state[action.payload.field][action.payload.subfield] =
          action.payload.value
      } else {
        state[action.payload.field] = action.payload.value
      }
      return { ...state }
    case 'TOGGLE_FIELD':
      state[action.payload.field] = action.payload.value
        ? action.payload.value
        : !state[action.payload.field]
      return { ...state }
    case 'SET_SNACKBAR':
      state.snackbar = action.payload
      return { ...state }
    case 'SET_GUIDED_TOUR':
      state.guidedTour = {
        ...state.guidedTour,
        [action.payload.field]: action.payload.value
      }
      return { ...state }
    default:
      return state
  }
}
